.booking-container {
    padding: 2rem;
}

.booking-header {
    margin-bottom: 2rem;
}

.header-title {
    font-size: 1.75rem;
    color: #333;
    margin-bottom: 0.5rem;
}

.header-breadcrumb {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #6c757d;
    font-size: 0.9rem;
}

.header-breadcrumb i {
    font-size: 1.1rem;
}

/* Card Styles */
.booking-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    position: relative;
    border-left: 4px solid transparent;
}

.booking-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Card Status Colors */
.booking-card.pending {
    border-left-color: #ffc107;
}

.booking-card.confirmed {
    border-left-color: #28a745;
}

.booking-card.cancelled {
    border-left-color: #dc3545;
}

.booking-card.completed {
    border-left-color: #6c757d;
}

.booking-card.pending-payment {
    border-left-color: #17a2b8;
}

/* Card Header */
.booking-card-header {
    padding: 1.25rem;
    border-bottom: 1px solid #eee;
    background-color: #f8f9fa;
}

.booking-header-content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.booking-id-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.booking-number {
    margin: 0;
    font-size: 1.1rem;
    color: #333;
    font-weight: 600;
}

.status-badge {
    display: inline-flex;
    align-items: center;
    gap: 0.35rem;
    padding: 0.5rem 0.75rem;
    font-size: 0.8rem;
    font-weight: 500;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: fit-content;
}

.status-badge i {
    font-size: 0.9rem;
}

.booking-cost {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
}

.cost-amount {
    font-size: 1.2rem;
    color: #28a745;
    font-weight: 600;
}

/* Card Body */
.booking-card-body {
    padding: 1.25rem;
}

.booking-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.info-item {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
}

.info-item i {
    color: #6c757d;
    font-size: 1.1rem;
    width: 20px;
    text-align: center;
}

.dates {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.dates small {
    color: #6c757d;
    margin-left: 0.5rem;
    font-weight: 500;
}

/* Card Footer */
.booking-card-footer {
    padding: 1rem;
    background: #f8f9fa;
    border-top: 1px solid #eee;
}

.actions-group {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    flex-wrap: wrap;
}

.btn-action {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    transition: transform 0.2s ease, opacity 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-action:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.btn-view { background-color: #17a2b8; }
.btn-service { background-color: #6610f2; }
.btn-user { background-color: #fd7e14; }
.btn-provider { background-color: #20c997; }
.btn-edit { background-color: #28a745; }
.btn-delete { background-color: #dc3545; }

/* Badges */
.badge {
    padding: 0.4em 0.8em;
    font-size: 0.75rem;
    font-weight: 500;
    border-radius: 6px;
}

/* Pagination */
.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.pagination {
    display: flex;
    gap: 0.5rem;
    background: white;
    padding: 0.75rem;
    border-radius: 12px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    align-items: center;
}

.page-btn {
    min-width: 36px;
    height: 36px;
    border: none;
    background: #f8f9fa;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6c757d;
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: 500;
}

.page-btn:hover:not(:disabled) {
    background: #e9ecef;
    color: #333;
    transform: translateY(-2px);
}

.page-btn.active {
    background: #007bff;
    color: white;
    box-shadow: 0 2px 4px rgba(0, 123, 255, 0.3);
}

.page-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.page-btn.page-nav {
    background-color: #f0f0f0;
    color: #495057;
}

.page-btn.page-nav:hover:not(:disabled) {
    background-color: #e2e6ea;
}

.page-ellipsis {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6c757d;
    font-weight: bold;
    padding: 0 0.5rem;
    user-select: none;
}

/* Skeleton Loading */
.skeleton {
    background: #f8f9fa;
    border-radius: 12px;
    overflow: hidden;
}

.skeleton-content {
    padding: 1.25rem;
}

.skeleton-line {
    height: 20px;
    background: #eee;
    border-radius: 4px;
    margin-bottom: 1rem;
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        opacity: 0.6;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 0.6;
    }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .booking-container {
        padding: 1rem;
    }
    
    .header-title {
        font-size: 1.5rem;
    }
    
    .booking-card-header {
        padding: 1rem;
    }
    
    .booking-card-body {
        padding: 1rem;
    }
    
    .cost-amount {
        font-size: 1.1rem;
    }
    
    .btn-action {
        width: 32px;
        height: 32px;
    }
    
    .status-badge {
        font-size: 0.7rem;
        padding: 0.4rem 0.6rem;
    }
}
