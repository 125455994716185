@import '../styles/tables.css';

.home-container {
  padding: 24px;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 16px;
  margin-bottom: 24px;
}

.stat-card {
  background: linear-gradient(135deg, var(--card-gradient-start), var(--card-gradient-end));
  border-radius: 12px;
  padding: 16px;
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  border: none;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.stat-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.stat-card.providers {
  --card-gradient-start: #1a237e;
  --card-gradient-end: #4a148c;
}

.stat-card.users {
  --card-gradient-start: #004d40;
  --card-gradient-end: #006064;
}

.stat-card.services {
  --card-gradient-start: #b71c1c;
  --card-gradient-end: #880e4f;
}

.stat-card.profits {
  --card-gradient-start: #1b5e20;
  --card-gradient-end: #2e7d32;
}

.stat-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  z-index: 1;
}

.stat-info h3 {
  font-size: 1.75rem;
  margin: 8px 0;
  font-weight: 700;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.stat-info p {
  margin: 0;
  opacity: 0.95;
  font-size: 1.1rem;
  font-weight: 500;
}

.stat-icon {
  background: rgba(255, 255, 255, 0.15);
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  backdrop-filter: blur(5px);
}

.stat-footer {
  margin-top: 12px;
  font-size: 0.95rem;
  opacity: 0.9;
  display: flex;
  align-items: center;
  gap: 6px;
}

.charts-section {
  margin-top: 2rem;
}

.chart-card {
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease;
  border: none;
  overflow: hidden;
}

.chart-card:hover {
  transform: translateY(-5px);
}

.chart-card .card-body {
  padding: 1.5rem;
}

.chart-card .card-title {
  color: #2d3748;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  text-align: right;
}

.chart-container {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 1rem;
  height: 300px;
}

.services-section {
  margin-top: 2rem;
  background: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.section-header h2 {
  color: #333;
  font-size: 1.25rem;
  margin: 0 0 0.75rem 0;
}

.card {
  transition: transform 0.2s ease-in-out;
  overflow: hidden;
  margin-bottom: 0.75rem;
}

.card:hover {
  transform: translateY(-3px);
}

.card {
  background: white;
  border-radius: 10px;
  border: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-5px);
}

.card-title {
  color: #2d3748;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.card-img-container {
  height: 140px;
  overflow: hidden;
  position: relative;
}

.card-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.card:hover .card-img-container img {
  transform: scale(1.05);
}

.card-body {
  padding: 0.75rem;
}

.card-text {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.btn-group .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.8125rem;
}

.btn-group .btn i {
  font-size: 0.8125rem;
}

.badge {
  font-size: 0.75rem;
  padding: 0.35em 0.65em;
}

.service-image {
  width: 44px;
  height: 44px;
  border-radius: 8px;
  object-fit: cover;
}

.action-buttons {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.btn-view {
  background-color: #4158D0;
  color: white;
  padding: 6px 12px;
  border-radius: 6px;
  text-decoration: none;
  font-size: 0.875rem;
  transition: background-color 0.3s ease;
}

.btn-edit {
  background-color: #00B09B;
  color: white;
  padding: 6px 12px;
  border-radius: 6px;
  text-decoration: none;
  font-size: 0.875rem;
  transition: background-color 0.3s ease;
}

.btn-delete {
  background-color: #FF416C;
  color: white;
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-view:hover { background-color: #3447b3; }
.btn-edit:hover { background-color: #009483; }
.btn-delete:hover { background-color: #e63a61; }

.services-table {
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

@media (max-width: 768px) {
  .home-container {
    padding: 16px;
  }

  .stats-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .action-buttons {
    flex-direction: column;
  }

  .btn-view, .btn-edit, .btn-delete {
    width: 100%;
    text-align: center;
  }
  
  .card-img-container {
    height: 120px;
  }
  
  .card-body {
    padding: 0.5rem;
  }
  
  .card-title {
    font-size: 0.9375rem;
  }
  
  .card-text {
    font-size: 0.8125rem;
  }
  
  .btn-group .btn {
    padding: 0.2rem 0.4rem;
    font-size: 0.75rem;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 1000px 100%;
  animation: shimmer 2s infinite;
  border-radius: 4px;
}
