.providers-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.providers-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 15px;
}

.providers-title {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.search-container {
  position: relative;
  flex: 1;
  max-width: 300px;
}

.search-input {
  width: 100%;
  padding: 10px 35px 10px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.balance-filter {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
}

.providers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.provider-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  transition: transform 0.2s;
}

.provider-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.provider-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.provider-image,
.provider-image-placeholder {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-left: 15px;
  object-fit: cover;
}

.provider-image-placeholder {
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #666;
}

.provider-info {
  flex: 1;
}

.provider-name {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.provider-email {
  margin: 5px 0 0;
  font-size: 14px;
  color: #666;
}

.provider-details {
  margin-bottom: 15px;
}

.provider-balance,
.provider-date {
  margin: 5px 0;
  font-size: 14px;
  color: #666;
}

.provider-actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.action-button {
  padding: 8px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: opacity 0.2s;
}

.action-button:hover {
  opacity: 0.9;
}

.action-button.view {
  background-color: #4CAF50;
}

.action-button.edit {
  background-color: #2196F3;
}

.action-button.delete {
  background-color: #f44336;
}

.action-button.wallet {
  background-color: #FF9800;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.pagination button {
  padding: 8px 15px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
  background-color: #f5f5f5;
}

.skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 8px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

/* Responsive design */
@media (max-width: 1024px) {
  .providers-container {
    padding: 16px;
  }
  
  .providers-grid {
    gap: 16px;
  }
}

@media (max-width: 768px) {
  .providers-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  
  .provider-card {
    padding: 10px;
  }
  
  .provider-actions {
    flex-wrap: wrap;
  }
  
  .action-button {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .pagination {
    overflow-x: auto;
    padding-bottom: 16px;
  }
}

/* RTL Support */
[dir="rtl"] .providers-grid {
  direction: rtl;
}

[dir="rtl"] .provider-card-header {
  flex-direction: row-reverse;
}

[dir="rtl"] .provider-actions {
  justify-content: flex-start;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.provider-card {
  animation: fadeIn 0.3s ease-out;
}
