.users-container {
  padding: 24px;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.users-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  flex-wrap: wrap;
  gap: 16px;
}

.users-title {
  margin: 0;
  font-size: 1.8rem;
  color: #1a1d21;
}

.search-container {
  position: relative;
  width: 300px;
}

.search-input {
  width: 100%;
  padding: 12px 40px 12px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.search-input:focus {
  outline: none;
  border-color: #38bdf8;
  box-shadow: 0 0 0 3px rgba(56, 189, 248, 0.1);
}

.search-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.users-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  margin-bottom: 24px;
}

.user-card {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.user-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.user-card-header {
  display: flex;
  align-items: center;
  gap: 16px;
}

.user-image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  object-fit: cover;
}

.user-image-placeholder {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #666;
}

.user-info {
  flex: 1;
}

.user-name {
  font-size: 1.2rem;
  font-weight: 600;
  color: #1a1d21;
  margin: 0 0 4px 0;
}

.user-email {
  color: #666;
  font-size: 0.9rem;
  margin: 0;
}

.user-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 12px;
  border-top: 1px solid #eee;
}

.user-detail-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #444;
  font-size: 0.95rem;
}

.user-detail-item i {
  color: #666;
  font-size: 1rem;
}

.action-buttons {
  display: flex;
  gap: 8px;
  margin-top: 12px;
}

.btn-view, .btn-edit, .btn-delete {
  flex: 1;
  padding: 8px;
  border-radius: 6px;
  border: none;
  font-size: 0.9rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.btn-view {
  background-color: #1a237e;
  color: white;
}

.btn-edit {
  background-color: #004d40;
  color: white;
}

.btn-delete {
  background-color: #b71c1c;
  color: white;
}

.btn-view:hover { background-color: #0d1b6d; }
.btn-edit:hover { background-color: #00352d; }
.btn-delete:hover { background-color: #961717; }

.pagination {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 32px;
}

.pagination-button {
  padding: 8px 16px;
  border: 1px solid #e0e0e0;
  background: white;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #444;
}

.pagination-button:hover:not(:disabled) {
  background-color: #f5f5f5;
}

.pagination-button.active {
  background-color: #1a237e;
  color: white;
  border-color: #1a237e;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Loading Skeleton */
.skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 8px;
}

@keyframes loading {
  0% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
}

/* Responsive Design */
@media (max-width: 768px) {
  .users-container {
    padding: 16px;
  }

  .users-header {
    flex-direction: column;
    align-items: stretch;
  }

  .search-container {
    width: 100%;
  }

  .users-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .action-buttons {
    flex-direction: column;
  }
}
