/* Global table styles */
.custom-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 1rem;
}

.custom-table th {
  background-color: #1a1d21;
  color: #ffffff;
  padding: 16px;
  font-weight: 600;
  text-align: right;
  font-size: 1.1rem;
  border-bottom: 2px solid #2a2d35;
}

.custom-table td {
  padding: 16px;
  border-top: 1px solid #eee;
  color: #2a2d35;
  vertical-align: middle;
  font-size: 1rem;
}

.custom-table tbody tr:hover {
  background-color: #f8f9fa;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin: 1rem 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.table-header {
  padding: 20px 24px;
  background-color: white;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-header h2 {
  margin: 0;
  font-size: 1.4rem;
  color: #1a1d21;
  font-weight: 600;
}

/* Custom scrollbar for table */
.table-responsive::-webkit-scrollbar {
  height: 6px;
}

.table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .custom-table th,
  .custom-table td {
    padding: 12px;
    font-size: 0.9rem;
  }
  
  .table-header h2 {
    font-size: 1.2rem;
  }
}
