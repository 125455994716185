/* Color Variables */
:root {
  --primary-gradient: linear-gradient(135deg, #7C3AED, #5B21B6);
  --primary-hover: linear-gradient(135deg, #6D28D9, #4C1D95);
  --bg-light: #F9FAFB;
  --bg-white: #FFFFFF;
  --border-light: #E5E7EB;
  --text-primary: #1F2937;
  --text-secondary: #6B7280;
  --accent-blue: #3B82F6;
  --accent-purple: #8B5CF6;
  --accent-green: #10B981;
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.07);
  --message-sent: #0EA5E9;
  --message-sent-hover: #0284C7;
  --message-received: #F3F4F6;
  --message-received-hover: #E5E7EB;
  --chat-bg-color: #eef2ff;
}

.chat-container {
  display: flex;
  height: 100vh;
  background-color: var(--bg-light);
  color: var(--text-primary);
  --whatsapp-bg-doodle: url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23000000' fill-opacity='0.05'%3E%3Cpath d='M50 50c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10zM10 10c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10S0 25.523 0 20s4.477-10 10-10zm10 8c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm40 40c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

/* Chat List Styles */
.chat-list {
  width: 380px;
  height: 100vh;
  background: var(--bg-white);
  border-right: 1px solid var(--border-light);
  display: flex;
  flex-direction: column;
}

.chat-list-header {
  padding: 16px;
  border-bottom: 1px solid var(--border-light);
  background: var(--bg-white);
}

.search-box {
  display: flex;
  align-items: center;
  background: #F3F4F6;
  padding: 8px 16px;
  border-radius: 8px;
  gap: 10px;
}

.search-box input {
  border: none;
  background: transparent;
  width: 100%;
  font-size: 15px;
  color: var(--text-primary);
}

.search-box input::placeholder {
  color: var(--text-secondary);
}

.chat-list-content {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
}

.chat-item {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid transparent;
}

.chat-item:hover {
  background: #F3F4F6;
  border-color: var(--border-light);
}

.chat-item.selected {
  background: #EEF2FF;
  border-color: #C7D2FE;
}

.chat-item-avatar {
  position: relative;
  margin-right: 12px;
}

.chat-item-avatar img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.online-indicator {
  position: absolute;
  bottom: 2px;
  right: 2px;
  width: 12px;
  height: 12px;
  background: var(--accent-green);
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.chat-item-content {
  flex: 1;
  min-width: 0;
}

.chat-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.chat-item-name {
  font-weight: 500;
  color: var(--text-primary);
  font-size: 15px;
}

.chat-item-time {
  font-size: 12px;
  color: var(--text-secondary);
}

.chat-item-message {
  color: var(--text-secondary);
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.unread-indicator {
  background: var(--accent-purple);
  color: white;
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  margin-left: 8px;
  padding: 0 6px;
}

.chat-item-user-info {
  display: flex;
  align-items: center;
  gap: 6px;
}

.chat-item-username {
  font-size: 13px;
  color: var(--text-secondary);
}

.username {
  font-size: 13px;
  color: var(--text-secondary);
  font-style: italic;
}

.sender-username {
  font-size: 0.8rem;
  color: var(--text-secondary);
  font-style: italic;
}

/* Chat Messages Styles */
.chat-messages {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: var(--bg-white);
}

.chat-header {
  padding: 16px;
  background: var(--bg-white);
  border-bottom: 1px solid var(--border-light);
}

.chat-header-users {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.chat-header-user {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 12px;
  background: #F3F4F6;
  flex: 1;
  transition: all 0.2s ease;
  border: 1px solid #E5E7EB;
}

.chat-header-user:hover {
  border-color: #D1D5DB;
  box-shadow: var(--shadow-sm);
}

.chat-header-separator {
  display: flex;
  align-items: center;
  color: var(--text-secondary);
  font-size: 1.2rem;
  background: #F3F4F6;
  padding: 8px;
  border-radius: 50%;
}

.chat-header-avatar {
  margin-right: 16px;
  position: relative;
}

.chat-header-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.chat-header-info {
  flex: 1;
}

.chat-header-info h2 {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
  margin-bottom: 4px;
}

.chat-header-details {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.phone-number {
  font-size: 13px;
  color: var(--text-secondary);
  background-color: #F3F4F6;
  padding: 2px 8px;
  border-radius: 8px;
}

.chat-header-status {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  color: var(--text-secondary);
}

.status-dot {
  width: 8px;
  height: 8px;
  background: var(--accent-green);
  border-radius: 50%;
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--chat-bg-color);
  background-image: none;
  position: relative;
  height: calc(100vh - 70px); /* Adjust for header height */
}

/* Remove the semi-transparent overlay since we're using a solid color */
.messages-container::before {
  content: none;
}

/* Ensure messages appear above the overlay */
.message {
  position: relative;
  z-index: 1;
}

.message {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  max-width: 70%;
  margin-bottom: 8px;
}

.message.sent {
  margin-left: auto;
  flex-direction: row-reverse;
}

.message-avatar {
  margin-top: 4px;
}

.message-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.message-content-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.message-bubble {
  padding: 12px 16px;
  border-radius: 16px;
  position: relative;
  transition: all 0.2s ease;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.message.received .message-bubble {
  background: var(--message-received);
  border-top-left-radius: 4px;
  margin-left: 0;
  color: var(--text-primary);
}

.message.sent .message-bubble {
  background: var(--message-sent);
  color: white;
  border-top-right-radius: 4px;
  margin-right: 0;
}

.message-bubble:hover {
  transform: none;
  box-shadow: none;
}

.message.sent .message-bubble:hover {
  background: var(--message-sent-hover);
}

.message.received .message-bubble:hover {
  background: var(--message-received-hover);
}

.message-content {
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}

.message-time {
  font-size: 11px;
  color: var(--text-secondary);
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.message.sent .message-time {
  justify-content: flex-end;
  color: rgba(255, 255, 255, 0.8);
}

.message.received .message-time {
  justify-content: flex-start;
}

.message-time::before {
  content: "•";
  font-size: 8px;
  opacity: 0.5;
}

.message.sent .message-time::before {
  order: 2;
}

/* Add smooth animation for new messages */
@keyframes messageAppear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.message {
  animation: messageAppear 0.3s ease-out;
}

/* Messages container enhancements */
.messages-container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--chat-bg-color);
}

/* Message group styling */
.message + .message {
  margin-top: 2px;
}

.message.sent + .message.sent,
.message.received + .message.received {
  margin-top: 2px;
}

.message.sent + .message.received,
.message.received + .message.sent {
  margin-top: 16px;
}

.input-actions {
  display: flex;
  gap: 8px;
}

.action-button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.action-button:hover {
  background: var(--bg-light);
}

.send-button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: var(--accent-blue);
  transition: opacity 0.2s;
}

.send-button:hover {
  opacity: 0.8;
}

.no-chat-selected {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);
  gap: 16px;
  background-color: var(--chat-bg-color);
  background-image: none;
  position: relative;
}

.no-chat-selected::before {
  content: none;
}

.no-chat-icon {
  font-size: 48px;
  color: var(--accent-purple);
  position: relative;
  z-index: 1;
}

.no-chat-selected span {
  position: relative;
  z-index: 1;
}

.messages-loading,
.no-messages {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--text-secondary);
  font-size: 14px;
  background-color: var(--chat-bg-color);
  background-image: none;
  position: relative;
}

.messages-loading::before,
.no-messages::before {
  content: none;
}

.messages-loading > *,
.no-messages > * {
  position: relative;
  z-index: 1;
}

/* Remove all theme-related background image styles */
.chat-theme-dark .messages-container,
.chat-theme-dark .no-chat-selected,
.chat-theme-dark .messages-loading,
.chat-theme-dark .no-messages {
  background-image: none;
  background-color: #1E293B; /* Dark theme background */
}

.chat-theme-dark .messages-container::before,
.chat-theme-dark .no-chat-selected::before,
.chat-theme-dark .messages-loading::before,
.chat-theme-dark .no-messages::before {
  content: none;
}

.chat-theme-whatsapp .messages-container,
.chat-theme-whatsapp .no-chat-selected,
.chat-theme-whatsapp .messages-loading,
.chat-theme-whatsapp .no-messages {
  background-image: none;
  background-color: var(--chat-bg-color);
}

.chat-theme-alt1 .messages-container,
.chat-theme-alt1 .no-chat-selected,
.chat-theme-alt1 .messages-loading,
.chat-theme-alt1 .no-messages {
  background-image: none;
  background-color: var(--chat-bg-color);
}

.chat-theme-alt2 .messages-container,
.chat-theme-alt2 .no-chat-selected,
.chat-theme-alt2 .messages-loading,
.chat-theme-alt2 .no-messages {
  background-image: none;
  background-color: var(--chat-bg-color);
}

/* Messages container enhancements */
.messages-container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--chat-bg-color);
}

/* Remove the custom WhatsApp-style background with doodles */
.messages-container::before {
  content: none;
}

/* Message type indicators */
.message-type-indicator {
  display: flex;
  align-items: center;
  margin-left: 8px;
  color: var(--primary-color, #00C5E9);
}

.message-type-indicator svg {
  width: 16px;
  height: 16px;
}

/* Image messages */
.message-type-image .message-bubble {
  padding: 4px;
  max-width: 300px;
}

.message-media {
  width: 100%;
  overflow: hidden;
}

.message-image {
  width: 100%;
  border-radius: 12px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.message-image:hover {
  transform: scale(1.02);
}

/* Voice messages */
.message-type-voice .message-bubble {
  min-width: 200px;
}

.message-voice {
  width: 100%;
}

.voice-player {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
}

.play-button {
  background-color: var(--primary-color, #00C5E9);
  color: white;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.play-button:hover {
  background-color: var(--primary-dark, #00a3c0);
}

.voice-waveform {
  display: flex;
  align-items: center;
  gap: 3px;
  flex-grow: 1;
  height: 30px;
}

.waveform-bar {
  background-color: var(--primary-color, #00C5E9);
  height: 100%;
  width: 3px;
  border-radius: 2px;
  animation: waveform 1.5s ease-in-out infinite;
}

.waveform-bar:nth-child(1) { height: 30%; animation-delay: 0.2s; }
.waveform-bar:nth-child(2) { height: 60%; animation-delay: 0.3s; }
.waveform-bar:nth-child(3) { height: 80%; animation-delay: 0.4s; }
.waveform-bar:nth-child(4) { height: 40%; animation-delay: 0.5s; }
.waveform-bar:nth-child(5) { height: 20%; animation-delay: 0.6s; }

.voice-duration {
  font-size: 12px;
  color: #6b7280;
  min-width: 32px;
  text-align: right;
}

/* Location messages */
.message-type-location .message-bubble {
  padding: 0;
  overflow: hidden;
  width: 300px;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: white !important;
}

.location-preview {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
}

.location-preview:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
}

.location-map {
  position: relative;
  width: 100%;
  height: 160px;
  overflow: hidden;
  background-color: #f3f4f6;
}

.map-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f4f6;
}

.map-loading::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--primary-color, #00C5E9);
  opacity: 0.3;
}

.static-map-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.location-preview:hover .static-map-image {
  transform: scale(1.05);
}

.location-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.1));
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 2;
}

.location-pin-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
}

.location-pin {
  background-color: var(--primary-color, #00C5E9);
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 3;
  animation: bounce 2s infinite;
}

.location-pin-shadow {
  position: absolute;
  bottom: 0;
  width: 20px;
  height: 6px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  filter: blur(2px);
  z-index: 1;
  animation: shadow-pulse 2s infinite;
}

.location-pin-pulse {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgba(0, 197, 233, 0.4);
  z-index: 2;
  animation: pulse 2s infinite;
}

.location-details {
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: white;
}

.location-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.location-title {
  font-weight: 600;
  font-size: 15px;
  color: var(--text-dark);
  flex: 1;
}

.location-view-btn {
  background-color: var(--primary-color, #00C5E9);
  color: white;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 10px;
  border-radius: 12px;
  transition: background-color 0.2s ease;
}

.location-preview:hover .location-view-btn {
  background-color: var(--primary-dark, #00a3c0);
}

.location-info {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.location-coordinates {
  font-size: 12px;
  color: var(--text-secondary);
  font-family: monospace;
  background-color: #f3f4f6;
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-block;
  width: fit-content;
}

.location-action {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  color: var(--primary-color, #00C5E9);
  margin-top: 4px;
  transition: color 0.2s ease;
}

.location-action svg {
  transition: transform 0.2s ease;
}

.location-preview:hover .location-action {
  color: var(--primary-dark, #00a3c0);
}

.location-preview:hover .location-action svg {
  transform: translateX(2px);
}

/* Animations */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-8px);
  }
  60% {
    transform: translateY(-4px);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

@keyframes shadow-pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 0.3;
  }
  50% {
    transform: scale(0.7);
    opacity: 0.1;
  }
}

.fallback-map {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #f0f0f0;
}

.osm-map {
  border: none;
  position: relative;
  z-index: 1;
}

/* Enhance message bubbles to stand out better on the image background */
.message-bubble {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.message.sent .message-bubble {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
}

.message.received .message-bubble {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.message-info {
  margin-bottom: 4px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.sender-name {
  font-weight: 600;
  font-size: 0.9rem;
  color: var(--text-primary);
}

.sender-phone {
  font-size: 0.8rem;
  color: var(--text-secondary);
}

.message.sent .message-info {
  flex-direction: row-reverse;
}

.user-role {
  font-size: 12px;
  padding: 4px 10px;
  border-radius: 12px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.user-role.user {
  background-color: #EEF2FF;
  color: #4F46E5;
}

.user-role.provider {
  background-color: #ECFDF5;
  color: #059669;
}

.avatar-circle {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.avatar-circle:hover {
  transform: scale(1.05);
  box-shadow: var(--shadow-md);
}

/* Update message spacing */
.message {
  margin-bottom: 8px;
}

/* Update message time for better contrast */
.message.sent .message-time {
  color: rgba(255, 255, 255, 0.8);
}

/* Update message group styling */
.message.sent + .message.sent .message-bubble,
.message.received + .message.received .message-bubble {
  border-radius: 16px;
}

.message.sent + .message.sent .message-bubble {
  border-top-right-radius: 8px;
}

.message.received + .message.received .message-bubble {
  border-top-left-radius: 8px;
}

/* Message type indicators */
.message-type-indicator {
  display: flex;
  align-items: center;
  margin-left: 8px;
  color: var(--primary-color, #00C5E9);
}

.message-type-indicator svg {
  width: 16px;
  height: 16px;
}

/* Image messages */
.message-type-image .message-bubble {
  padding: 4px;
  max-width: 300px;
}

.message-media {
  width: 100%;
  overflow: hidden;
}

.message-image {
  width: 100%;
  border-radius: 12px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.message-image:hover {
  transform: scale(1.02);
}

/* Voice messages */
.message-type-voice .message-bubble {
  min-width: 200px;
}

.message-voice {
  width: 100%;
}

.voice-player {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
}

.play-button {
  background-color: var(--primary-color, #00C5E9);
  color: white;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.play-button:hover {
  background-color: var(--primary-dark, #00a3c0);
}

.voice-waveform {
  display: flex;
  align-items: center;
  gap: 3px;
  flex-grow: 1;
  height: 30px;
}

.waveform-bar {
  background-color: var(--primary-color, #00C5E9);
  height: 100%;
  width: 3px;
  border-radius: 2px;
  animation: waveform 1.5s ease-in-out infinite;
}

.waveform-bar:nth-child(1) { height: 30%; animation-delay: 0.2s; }
.waveform-bar:nth-child(2) { height: 60%; animation-delay: 0.3s; }
.waveform-bar:nth-child(3) { height: 80%; animation-delay: 0.4s; }
.waveform-bar:nth-child(4) { height: 40%; animation-delay: 0.5s; }
.waveform-bar:nth-child(5) { height: 20%; animation-delay: 0.6s; }

.voice-duration {
  font-size: 12px;
  color: #6b7280;
  min-width: 32px;
  text-align: right;
}

/* Location messages */
.message-type-location .message-bubble {
  padding: 0;
  overflow: hidden;
  width: 300px;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: white !important;
}

.location-preview {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
}

.location-preview:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
}

.location-map {
  position: relative;
  width: 100%;
  height: 160px;
  overflow: hidden;
}

.static-map-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.location-preview:hover .static-map-image {
  transform: scale(1.05);
}

.location-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.1));
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 2;
}

.location-pin-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
}

.location-pin {
  background-color: var(--primary-color, #00C5E9);
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 3;
  animation: bounce 2s infinite;
}

.location-pin-shadow {
  position: absolute;
  bottom: 0;
  width: 20px;
  height: 6px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  filter: blur(2px);
  z-index: 1;
  animation: shadow-pulse 2s infinite;
}

.location-pin-pulse {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgba(0, 197, 233, 0.4);
  z-index: 2;
  animation: pulse 2s infinite;
}

.location-details {
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: white;
}

.location-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.location-title {
  font-weight: 600;
  font-size: 15px;
  color: var(--text-dark);
  flex: 1;
}

.location-view-btn {
  background-color: var(--primary-color, #00C5E9);
  color: white;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 10px;
  border-radius: 12px;
  transition: background-color 0.2s ease;
}

.location-preview:hover .location-view-btn {
  background-color: var(--primary-dark, #00a3c0);
}

.location-info {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.location-coordinates {
  font-size: 12px;
  color: var(--text-secondary);
  font-family: monospace;
  background-color: #f3f4f6;
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-block;
  width: fit-content;
}

.location-action {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  color: var(--primary-color, #00C5E9);
  margin-top: 4px;
  transition: color 0.2s ease;
}

.location-action svg {
  transition: transform 0.2s ease;
}

.location-preview:hover .location-action {
  color: var(--primary-dark, #00a3c0);
}

.location-preview:hover .location-action svg {
  transform: translateX(2px);
}

/* Animations */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-8px);
  }
  60% {
    transform: translateY(-4px);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

@keyframes shadow-pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 0.3;
  }
  50% {
    transform: scale(0.7);
    opacity: 0.1;
  }
}

.fallback-map {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #f0f0f0;
}

.osm-map {
  border: none;
  position: relative;
  z-index: 1;
}
