.provider-detail-container {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.provider-header {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.header-title {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0;
}

.header-breadcrumb {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #6b7280;
  font-size: 14px;
}

.header-breadcrumb i {
  font-size: 16px;
}

.provider-card {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 24px;
  overflow: hidden;
}

.card-header {
  padding: 20px 24px;
  border-bottom: 1px solid #e5e7eb;
  background: #f8fafc;
}

.card-header h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
}

.card-body {
  padding: 24px;
}

.info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
}

.info-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.info-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.info-label {
  font-size: 14px;
  font-weight: 500;
  color: #6b7280;
}

.info-value {
  font-size: 16px;
  color: #1a1a1a;
  line-height: 1.5;
}

.info-value.about {
  background: #f9fafb;
  padding: 12px;
  border-radius: 8px;
  min-height: 80px;
}

.profile-image-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background: #f9fafb;
  border-radius: 12px;
}

.profile-image-container {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e5e7eb;
  color: #9ca3af;
  font-size: 64px;
}

.balance-section {
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
}

.balance-section.balance-positive {
  background: #dcfce7;
  color: #166534;
}

.balance-section.balance-negative {
  background: #fee2e2;
  color: #991b1b;
}

.balance-section.balance-zero {
  background: #f3f4f6;
  color: #4b5563;
}

.balance-label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.balance-value {
  font-size: 24px;
  font-weight: 600;
}

.services-section {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  padding: 24px;
}

/* Loading State */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f4f6;
  border-radius: 50%;
  border-top-color: #3b82f6;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Error State */
.error-container {
  padding: 24px;
  background: #fee2e2;
  border-radius: 12px;
  color: #991b1b;
  text-align: center;
  margin: 24px 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .provider-detail-container {
    padding: 16px;
  }

  .provider-header {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
  }

  .info-grid {
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .profile-image-container {
    width: 120px;
    height: 120px;
  }
}