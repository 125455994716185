.payment-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1060;
    animation: fadeIn 0.3s ease-out;
}

.payment-modal {
    background: white;
    border-radius: 1rem;
    width: 90%;
    max-width: 500px;
    padding: 2rem;
    position: relative;
    animation: slideIn 0.3s ease-out;
}

.payment-modal-content {
    text-align: center;
}

.payment-modal-icon {
    font-size: 3rem;
    color: #28a745;
    margin-bottom: 1rem;
    animation: bounceIn 0.5s ease-out;
}

.payment-modal-title {
    color: #2d3748;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.payment-modal-message {
    color: #4a5568;
    margin-bottom: 1.5rem;
    line-height: 1.6;
}

.amount {
    font-weight: 600;
    color: #28a745;
}

.provider-name {
    font-weight: 600;
    color: #2d3748;
}

.payment-modal-actions {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
}

.payment-modal-actions button {
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    font-weight: 500;
    transition: all 0.2s;
}

.payment-modal-actions button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.payment-modal-actions .btn-cancel {
    background-color: #e2e8f0;
    color: #4a5568;
    border: none;
}

.payment-modal-actions .btn-cancel:hover:not(:disabled) {
    background-color: #cbd5e0;
}

.payment-modal-actions .btn-confirm {
    background-color: #28a745;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.payment-modal-actions .btn-confirm:hover:not(:disabled) {
    background-color: #218838;
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes bounceIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/* RTL Support */
[dir="rtl"] .payment-modal-actions {
    flex-direction: row-reverse;
}

/* Responsive Design */
@media (max-width: 576px) {
    .payment-modal {
        width: 95%;
        padding: 1.5rem;
    }

    .payment-modal-actions {
        flex-direction: column-reverse;
    }

    .payment-modal-actions button {
        width: 100%;
    }
}
