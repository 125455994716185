:root {
  --sidebar-width: 280px;
  --sidebar-collapsed-width: 80px;
  --sidebar-bg: #1a1d21;
  --sidebar-hover: #2a2d35;
  --active-bg: rgba(56, 189, 248, 0.15);
  --active-color: #38bdf8;
  --text-primary: rgba(255, 255, 255, 0.9);
  --text-secondary: rgba(255, 255, 255, 0.6);
  --border-color: rgba(255, 255, 255, 0.1);
}

.sidebar {
  width: var(--sidebar-width);
  height: 100vh;
  background-color: var(--sidebar-bg);
  border-left: 1px solid var(--border-color);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
}

.sidebar.collapsed {
  width: var(--sidebar-collapsed-width);
}

.sidebar-header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
  background-color: rgba(255, 255, 255, 0.02);
}

.logo {
  height: 40px;
  transition: all 0.3s ease;
  filter: brightness(1.2);
}

.sidebar.collapsed .logo {
  width: 30px;
  height: 30px;
}

.collapse-btn {
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.collapse-btn:hover {
  background-color: var(--sidebar-hover);
  color: var(--text-primary);
}

.menu-container {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.menu-item {
  margin-bottom: 2px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.menu-item-header {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;
  color: var(--text-secondary);
  font-size: 18px;
}

.menu-item-header i {
  font-size: 20px;
  min-width: 24px;
}

.menu-label {
  margin-right: 12px;
  flex: 1;
  font-weight: 500;
}

.arrow-icon {
  font-size: 14px;
  transition: transform 0.3s ease;
}

.submenu {
  margin-top: 5px;
  margin-right: 20px;
  background-color: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  overflow: hidden;
}

.submenu-item {
  padding: 10px 16px 10px 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--text-secondary);
  transition: all 0.2s ease;
  font-size: 15px;
}

.submenu-item i {
  font-size: 16px;
  min-width: 24px;
  margin-left: 8px;
}

.menu-item.active > .menu-item-header {
  background-color: var(--active-bg);
  color: var(--active-color);
}

.submenu-item:hover {
  background-color: var(--sidebar-hover);
  color: var(--active-color);
  border-right-color: var(--active-color);
}

.submenu-item.active {
  background-color: var(--active-bg);
  color: var(--active-color);
  border-right-color: var(--active-color);
}

.sidebar-footer {
  padding: 20px;
  border-top: 1px solid var(--border-color);
  background-color: rgba(255, 255, 255, 0.02);
}

.logout-button {
  display: flex;
  align-items: center;
  padding: 14px 16px;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;
  color: #ff4d4f;
  gap: 12px;
  font-size: 16px;
}

.logout-button i {
  margin-left: 12px;
  font-size: 18px;
}

.logout-button:hover {
  background-color: rgba(255, 77, 79, 0.15);
}

.sidebar.collapsed .menu-label,
.sidebar.collapsed .arrow-icon,
.sidebar.collapsed .logout-button span {
  display: none;
}

/* Custom scrollbar */
.menu-container::-webkit-scrollbar {
  width: 5px;
}

.menu-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
}

.menu-container::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.menu-container::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .sidebar {
    transform: translateX(var(--sidebar-width));
  }
  
  .sidebar.show {
    transform: translateX(0);
  }

  .sidebar-overlay {
    display: block;
  }

  .sidebar.collapsed {
    transform: translateX(var(--sidebar-collapsed-width));
  }

  .sidebar.collapsed.show {
    transform: translateX(0);
  }

  .menu-container {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 480px) {
  :root {
    --sidebar-width: 100%;
    --sidebar-collapsed-width: 100%;
  }

  .sidebar {
    width: 100%;
  }

  .sidebar.collapsed {
    width: 100%;
  }

  .menu-item {
    margin: 4px 8px;
  }

  .menu-item-header {
    padding: 14px 16px;
  }

  .submenu-item {
    padding: 12px 16px 12px 35px;
  }

  .sidebar-header {
    padding: 0 16px;
  }

  .logo {
    height: 36px;
  }
}

/* Touch Device Optimizations */
@media (hover: none) {
  .menu-item-header:hover,
  .submenu-item:hover,
  .logout-button:hover {
    transform: none;
  }

  .menu-item-header:active,
  .submenu-item:active,
  .logout-button:active {
    opacity: 0.7;
  }
}
