.price-input-group {
    position: relative;
    display: flex;
    align-items: center;
}

.price-input-group .form-control {
    padding-right: 70px;
    text-align: right;
    font-size: 1rem;
    height: 45px;
    border-radius: 8px !important;
    border: 1px solid #dee2e6;
    transition: all 0.2s ease-in-out;
}

.price-input-group .form-control:focus {
    border-color: #3182ce;
    box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.1);
}

.price-input-group .currency-suffix {
    position: absolute;
    right: 1px;
    top: 1px;
    bottom: 1px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    background-color: #f8f9fa;
    color: #6c757d;
    border-right: 1px solid #dee2e6;
    border-radius: 0 7px 7px 0;
    font-size: 0.9rem;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
}

.price-input-group .form-control:focus + .currency-suffix {
    background-color: #e9ecef;
    border-color: #3182ce;
}

.price-input-group .price-icon {
    position: absolute;
    left: 15px;
    color: #6c757d;
    pointer-events: none;
}

/* Add some spacing for the price label */
.price-label {
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #2d3748;
    font-size: 0.95rem;
}

/* Style for the required asterisk */
.price-label.required::after {
    content: '*';
    color: #dc3545;
    margin-right: 4px;
}

/* Remove spinner buttons from number input */
.price-input-group input[type="number"]::-webkit-inner-spin-button,
.price-input-group input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.price-input-group input[type="number"] {
    -moz-appearance: textfield;
}
