.service-detail-container {
    padding: 2rem;
    background-color: #f8f9fa;
    min-height: 100vh;
}

/* Loader */
.service-detail-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
}

/* Header Styles */
.service-header {
    margin-bottom: 2rem;
}

.breadcrumb {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}

.breadcrumb-item {
    color: #6c757d;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.breadcrumb-item:hover {
    color: #0d6efd;
}

.breadcrumb-item.active {
    color: #212529;
}

.header-content {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.header-main {
    flex: 1;
}

.service-title {
    font-size: 1.75rem;
    margin-bottom: 1rem;
    color: #212529;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.service-id {
    font-size: 1rem;
    color: #6c757d;
    background: #f8f9fa;
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
}

.service-badges {
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;
}

.status-badge, .price-badge {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    font-size: 0.9rem;
}

.status-badge.available {
    background-color: #d1fae5;
    color: #065f46;
}

.status-badge.booked {
    background-color: #fee2e2;
    color: #991b1b;
}

.price-badge {
    background-color: #e0e7ff;
    color: #3730a3;
}

.header-actions {
    display: flex;
    gap: 1rem;
}

/* Content Cards */
.content-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    margin-bottom: 1.5rem;
    overflow: hidden;
}

.card-header {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #e9ecef;
}

.card-header h2 {
    font-size: 1.25rem;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: #212529;
}

.card-header h2 i {
    color: #6c757d;
}

.card-body {
    padding: 1.5rem;
}

/* Gallery Styles */
.gallery-container {
    padding: 1rem;
}

.main-image-container {
    position: relative;
    width: 100%;
    height: 400px;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 1rem;
}

.main-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: #f8f9fa;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.main-image:hover {
    transform: scale(1.02);
}

.thumbnails-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 0.5rem;
    max-height: 120px;
    overflow-y: auto;
    padding: 0.5rem;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

.thumbnails-container::-webkit-scrollbar {
    width: 6px;
}

.thumbnails-container::-webkit-scrollbar-track {
    background: transparent;
}

.thumbnails-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 3px;
}

.thumbnail {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    opacity: 0.7;
    transition: all 0.3s ease;
}

.thumbnail:hover {
    opacity: 0.9;
    transform: translateY(-2px);
}

.thumbnail.active {
    opacity: 1;
    box-shadow: 0 0 0 2px #0d6efd;
}

.thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 2;
}

.nav-button:hover {
    background: rgba(0, 0, 0, 0.7);
    transform: translateY(-50%) scale(1.1);
}

.nav-button.prev {
    right: 1rem;
}

.nav-button.next {
    left: 1rem;
}

.fullscreen-button {
    position: absolute;
    top: 1rem;
    left: 1rem;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 2;
}

.fullscreen-button:hover {
    background: rgba(0, 0, 0, 0.7);
    transform: scale(1.1);
}

.image-counter {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    z-index: 2;
}

.gallery-card {
    margin-bottom: 2rem;
}

.service-image-gallery {
    border-radius: 0 0 12px 12px;
    overflow: hidden;
}

.image-counter {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
    font-size: 0.9rem;
}

/* Fullscreen Styles */
.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.fullscreen-content {
    position: relative;
    width: 90%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullscreen-content img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.fullscreen-controls {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 2rem;
    z-index: 1001;
}

.close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 1001;
}

.close-button:hover {
    background: rgba(0, 0, 0, 0.7);
    transform: scale(1.1);
}

/* Info List Styles */
.info-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.info-item {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.info-item i {
    color: #6c757d;
    font-size: 1.25rem;
    width: 24px;
    text-align: center;
}

.info-content {
    flex: 1;
}

.info-content label {
    display: block;
    color: #6c757d;
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
}

.info-content span {
    color: #212529;
    font-weight: 500;
}

/* Description Styles */
.service-description {
    color: #4b5563;
    line-height: 1.6;
    margin: 0;
}

/* Responsive Design */
@media (max-width: 991.98px) {
    .service-detail-container {
        padding: 1rem;
    }

    .header-content {
        flex-direction: column;
        gap: 1rem;
    }

    .header-actions {
        width: 100%;
    }

    .header-actions .btn {
        flex: 1;
        text-align: center;
    }
}

@media (max-width: 575.98px) {
    .service-badges {
        flex-direction: column;
    }

    .status-badge, .price-badge {
        width: fit-content;
    }
}

@media (max-width: 768px) {
    .main-image-container {
        height: 300px;
    }

    .thumbnails-container {
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    }

    .thumbnail {
        width: 80px;
        height: 80px;
    }

    .nav-button, .fullscreen-button, .close-button {
        width: 36px;
        height: 36px;
    }

    .image-counter {
        padding: 0.4rem 0.8rem;
        font-size: 0.8rem;
    }
}

@media (max-width: 480px) {
    .main-image-container {
        height: 250px;
    }

    .thumbnails-container {
        grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
    }

    .thumbnail {
        width: 60px;
        height: 60px;
    }

    .nav-button, .fullscreen-button, .close-button {
        width: 32px;
        height: 32px;
    }
}
