
.main-container{
  height: 100vh;
}
.top-view img{
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.tab-bar{
  width: 100%;
  height: 40px;
  background-color: #b8bfc1;
}

#name{
  position:absolute;
  top: 50px;
  right: 300px;
  color: white;
}

.right-view {
  position: absolute;
  top: 70px;
 right: 10px;

  width:250px ;
  height: 500px;
}

#avatar {
    width: 150px;
    height: 150px;
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid white;
    box-sizing: border-box;
}


#avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Provider Edit Page */
.provider-edit-container {
  padding: 2rem;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.page-header {
  margin-bottom: 2rem;
}

.page-title {
  font-size: 1.75rem;
  color: #2d3748;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #718096;
  font-size: 0.875rem;
}

.edit-card {
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  padding: 2rem;
}

.edit-form {
  width: 100%;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.form-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.section-title {
  font-size: 1.25rem;
  color: #2d3748;
  font-weight: 600;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e2e8f0;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-group label {
  font-size: 0.875rem;
  color: #4a5568;
  font-weight: 500;
}

.input-group input,
.input-group textarea {
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  transition: all 0.2s;
}

.input-group input:focus,
.input-group textarea:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.1);
}

.input-group textarea {
  resize: vertical;
  min-height: 100px;
}

.images-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.image-upload {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.image-preview {
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 0.5rem;
  overflow: hidden;
  background-color: #f7fafc;
  border: 2px dashed #e2e8f0;
}

.image-preview.banner {
  height: 120px;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;
}

.image-preview:hover .upload-overlay {
  opacity: 1;
}

.file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-overlay i {
  color: white;
  font-size: 1.5rem;
  pointer-events: none;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #e2e8f0;
}

.btn-cancel,
.btn-save {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.btn-cancel {
  background-color: #fff;
  border: 1px solid #e2e8f0;
  color: #4a5568;
}

.btn-cancel:hover {
  background-color: #f7fafc;
}

.btn-save {
  background-color: #4299e1;
  border: none;
  color: white;
}

.btn-save:hover {
  background-color: #3182ce;
}

/* Responsive Design */
@media (max-width: 768px) {
  .provider-edit-container {
    padding: 1rem;
  }

  .form-grid {
    grid-template-columns: 1fr;
  }

  .images-section {
    grid-template-columns: 1fr;
  }

  .form-actions {
    flex-direction: column-reverse;
  }

  .btn-cancel,
  .btn-save {
    width: 100%;
  }
}

/* RTL Support */
[dir="rtl"] .breadcrumb,
[dir="rtl"] .form-actions {
  flex-direction: row-reverse;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.edit-card {
  animation: fadeIn 0.3s ease-out;
}