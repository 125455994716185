:root {
  --primary-color: #00C5E9;
  --primary-dark: #00a3c0;
  --primary-light: #e6faff;
  --secondary-color: #ff6632;
  --secondary-dark: #e04e1a;
  --text-dark: #1f2937;
  --text-light: #6b7280;
  --background-light: #f0fbff;
  --background-white: #ffffff;
  --error-color: #e72e2e;
  --success-color: #12bf24;
}

.login-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-light);
  font-family: 'Tajawal', sans-serif;
  position: relative;
  overflow: hidden;
}

.login-container::before {
  content: '';
  position: absolute;
  top: -10%;
  right: -10%;
  width: 60%;
  height: 70%;
  background-color: var(--primary-color);
  border-radius: 50%;
  opacity: 0.1;
  z-index: 0;
}

.login-container::after {
  content: '';
  position: absolute;
  bottom: -10%;
  left: -10%;
  width: 70%;
  height: 60%;
  background-color: var(--secondary-color);
  border-radius: 50%;
  opacity: 0.1;
  z-index: 0;
}

.login-content {
  display: flex;
  width: 90%;
  max-width: 1000px;
  background-color: var(--background-white);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 197, 233, 0.15);
  position: relative;
  z-index: 1;
}

.login-left-side {
  width: 50%;
  background-color: var(--primary-color);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.login-left-side::before {
  content: '';
  position: absolute;
  top: -20%;
  right: -20%;
  width: 80%;
  height: 80%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.login-left-side::after {
  content: '';
  position: absolute;
  bottom: -20%;
  left: -20%;
  width: 80%;
  height: 80%;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
}

.login-text-overlay {
  position: relative;
  z-index: 2;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.brand-logo {
  margin-bottom: 2rem;
  width: 120px;
  height: 120px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 20px rgba(0, 197, 233, 0.2);
  transform: rotate(10deg);
  transition: transform 0.5s ease;
}

.brand-logo:hover {
  transform: rotate(0deg);
}

.logo-image {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.brand-name-container {
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.animated-brand-name {
  font-size: 4rem;
  font-weight: 700;
  margin: 0;
  display: flex;
  justify-content: center;
}

.letter {
  display: inline-block;
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.animation-complete .letter {
  opacity: 1;
  transform: translateY(0);
}

.brand-tagline {
  font-size: 1.2rem;
  margin-bottom: 2.5rem;
  opacity: 0;
  animation: fadeIn 1s ease forwards;
  animation-delay: 0.8s;
}

.brand-features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 300px;
  opacity: 0;
  animation: fadeIn 1s ease forwards;
  animation-delay: 1s;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1rem 1.5rem;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.feature-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateX(-5px);
}

.feature-icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.feature-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: brightness(0) invert(1);
}

.login-right-side {
  width: 50%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--background-white);
}

.login-form-wrapper {
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
}

.login-header {
  margin-bottom: 2.5rem;
  text-align: center;
}

.welcome-text h2 {
  font-size: 2.2rem;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.wave-emoji {
  display: inline-block;
  animation: wave 2s infinite;
  transform-origin: 70% 70%;
}

.welcome-text p {
  font-size: 1.1rem;
  color: var(--text-light);
  margin: 0;
}

.error-message {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background-color: rgba(231, 46, 46, 0.1);
  color: var(--error-color);
  padding: 1rem 1.25rem;
  border-radius: 12px;
  margin-bottom: 1.5rem;
  font-size: 0.95rem;
  border-left: 4px solid var(--error-color);
}

.error-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.form-fields {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.form-group label {
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-dark);
}

.input-group {
  position: relative;
}

.input-group input {
  width: 100%;
  padding: 1.25rem 1.5rem;
  padding-right: 3rem;
  border: 2px solid #e5e7eb;
  border-radius: 12px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background-color: var(--background-white);
  color: var(--text-dark);
}

.input-group input::placeholder {
  color: #9ca3af;
}

.input-group input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 4px rgba(0, 197, 233, 0.15);
}

.input-group input.error {
  border-color: var(--error-color);
  background-color: rgba(231, 46, 46, 0.05);
}

.input-icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  opacity: 0.7;
}

.submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 1rem 1.5rem;
  background: linear-gradient(135deg, var(--primary-color), #00a3c0);
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.submit-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: all 0.6s ease;
}

.submit-button:hover::before {
  left: 100%;
}

.submit-button:hover {
  background: linear-gradient(135deg, #00d2f8, var(--primary-color));
  transform: translateY(-2px);
  box-shadow: 0 8px 15px rgba(0, 197, 233, 0.25);
}

.submit-button:active {
  transform: translateY(0);
}

.submit-button:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.button-icon {
  width: 24px;
  height: 24px;
  filter: brightness(0) invert(1);
}

.login-footer {
  margin-top: 2.5rem;
  text-align: center;
  color: var(--text-light);
  font-size: 0.95rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes wave {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(14deg); }
  20% { transform: rotate(-8deg); }
  30% { transform: rotate(14deg); }
  40% { transform: rotate(-4deg); }
  50% { transform: rotate(10deg); }
  60% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
}

@media (max-width: 992px) {
  .login-content {
    flex-direction: column;
    height: auto;
    max-height: none;
  }
  
  .login-left-side,
  .login-right-side {
    width: 100%;
  }
  
  .login-left-side {
    padding: 3rem 2rem;
  }
  
  .animated-brand-name {
    font-size: 3rem;
  }
  
  .brand-features {
    flex-direction: row;
    max-width: 100%;
    justify-content: center;
    gap: 1rem;
  }
  
  .feature-item {
    flex: 1;
    max-width: 150px;
    padding: 0.75rem;
  }
}

@media (max-width: 576px) {
  .login-content {
    width: 95%;
    border-radius: 12px;
  }
  
  .login-left-side,
  .login-right-side {
    padding: 2rem 1.5rem;
  }
  
  .brand-logo {
    width: 100px;
    height: 100px;
  }
  
  .logo-image {
    width: 70px;
    height: 70px;
  }
  
  .animated-brand-name {
    font-size: 2.5rem;
  }
  
  .brand-features {
    flex-direction: column;
    align-items: center;
  }
  
  .feature-item {
    width: 100%;
    max-width: 250px;
  }
  
  .welcome-text h2 {
    font-size: 1.8rem;
  }
  
  .welcome-text p {
    font-size: 1rem;
  }
  
  .input-group input {
    padding: 1.1rem 1.25rem;
    padding-right: 2.5rem;
  }
  
  .submit-button {
    padding: 0.875rem 1.25rem;
    font-size: 1rem;
  }
}
