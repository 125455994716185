.booking-detail-container {
    padding: 2rem;
    background-color: #f8f9fa;
    min-height: 100vh;
}

.booking-detail-header {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #e9ecef;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.booking-detail-header h3 {
    color: #2c3e50;
    font-weight: 600;
    margin: 0;
}

.booking-detail-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
    border: none;
}

.booking-detail-section {
    padding: 1.5rem;
    border-bottom: 1px solid #eee;
}

.booking-detail-section:last-child {
    border-bottom: none;
}

.detail-label {
    font-size: 0.9rem;
    color: #6c757d;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.detail-value {
    background-color: #f8f9fa;
    border: 1px solid #e9ecef;
    border-radius: 6px;
    padding: 0.75rem;
    color: #2c3e50;
    font-size: 0.95rem;
    min-height: 42px;
    display: flex;
    align-items: center;
}

.status-badge {
    padding: 0.5rem 1rem;
    border-radius: 50px;
    font-size: 0.9rem;
    font-weight: 500;
    display: inline-block;
}

.status-pending {
    background-color: #fff3cd;
    color: #856404;
}

.status-confirmed {
    background-color: #d4edda;
    color: #155724;
}

.status-cancelled {
    background-color: #f8d7da;
    color: #721c24;
}

.status-ended {
    background-color: #e2e3e5;
    color: #383d41;
}

.section-title {
    font-size: 1.1rem;
    color: #2c3e50;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

.back-button {
    padding: 0.5rem 1rem;
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.back-button:hover {
    background-color: #5a6268;
}

@media (max-width: 768px) {
    .booking-detail-container {
        padding: 1rem;
    }
    
    .detail-group {
        margin-bottom: 1rem;
    }
}
