.report-card {
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    cursor: pointer;
}

.report-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.report-filters {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1.5rem;
}

.report-filters select {
    min-width: 150px;
    border-radius: 6px;
}

.chart-container {
    position: relative;
    margin: auto;
    height: 300px;
}

.stat-card {
    transition: transform 0.2s;
    border: none;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 10px;
}

.stat-card:hover {
    transform: translateY(-5px);
}

.stat-card .card-body {
    padding: 1.5rem;
}

.stat-card.new-items {
    background: linear-gradient(45deg, #4CAF50, #81C784);
    color: white;
}

.stat-card.total-items {
    background: linear-gradient(45deg, #2196F3, #64B5F6);
    color: white;
}

.stat-card.active-items {
    background: linear-gradient(45deg, #FF9800, #FFB74D);
    color: white;
}

.stat-card.average-items {
    background: linear-gradient(45deg, #9C27B0, #BA68C8);
    color: white;
}

.stat-card .text-muted {
    font-size: 0.875rem;
    color: rgba(255,255,255,0.8) !important;
}

.stat-card h3 {
    font-weight: 600;
    color: white;
}

.stat-card h6 {
    color: white;
}

.chart-card {
    border: none;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 10px;
}

.chart-card .card-body {
    padding: 1.25rem;
}

.chart-card .card-title {
    font-size: 1rem;
    font-weight: 600;
    color: #4a5568;
    margin-bottom: 1rem;
}

.form-select {
    min-width: 120px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .report-filters {
        flex-direction: column;
        gap: 1rem;
    }
    
    .report-filters select {
        width: 100%;
    }
    
    .chart-container {
        height: 250px;
    }
}
