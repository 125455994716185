.user-detail-container {
    padding: 24px;
    background-color: #f8f9fa;
    min-height: 100vh;
}

.user-detail-header {
    margin-bottom: 32px;
}

.user-detail-title {
    font-size: 1.8rem;
    color: #1a1d21;
    margin-bottom: 8px;
}

.user-detail-subtitle {
    color: #666;
    font-size: 1rem;
}

.user-detail-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    margin-bottom: 24px;
}

.user-detail-profile {
    padding: 24px;
    display: flex;
    align-items: center;
    gap: 24px;
    border-bottom: 1px solid #eee;
}

.user-detail-avatar {
    width: 120px;
    height: 120px;
    border-radius: 60px;
    object-fit: cover;
    border: 4px solid #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.user-detail-info {
    flex: 1;
}

.user-detail-name {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1a1d21;
    margin-bottom: 8px;
}

.user-detail-email {
    color: #666;
    font-size: 1rem;
    margin-bottom: 16px;
}

.user-detail-stats {
    display: flex;
    gap: 24px;
}

.user-detail-stat {
    text-align: center;
}

.user-detail-stat-value {
    font-size: 1.2rem;
    font-weight: 600;
    color: #1a1d21;
}

.user-detail-stat-label {
    color: #666;
    font-size: 0.9rem;
}

.user-detail-content {
    padding: 24px;
}

.user-detail-section {
    margin-bottom: 24px;
}

.user-detail-section-title {
    font-size: 1.2rem;
    color: #1a1d21;
    margin-bottom: 16px;
    font-weight: 600;
}

.user-detail-field {
    margin-bottom: 16px;
}

.user-detail-label {
    display: block;
    color: #666;
    font-size: 0.9rem;
    margin-bottom: 8px;
}

.user-detail-value {
    background-color: #f8f9fa;
    padding: 12px 16px;
    border-radius: 8px;
    color: #1a1d21;
    font-size: 1rem;
    border: 1px solid #eee;
}

.user-detail-actions {
    padding: 24px;
    display: flex;
    gap: 12px;
    border-top: 1px solid #eee;
}

.user-detail-button {
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;
    border: none;
}

.user-detail-button.primary {
    background-color: #1a237e;
    color: white;
}

.user-detail-button.primary:hover {
    background-color: #0d1b6d;
}

.user-detail-button.secondary {
    background-color: #f8f9fa;
    color: #1a1d21;
    border: 1px solid #eee;
}

.user-detail-button.secondary:hover {
    background-color: #eee;
}

/* Edit Form Styles */
.user-edit-form {
    padding: 24px;
}

.user-edit-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
}

.user-edit-field {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.user-edit-label {
    color: #666;
    font-size: 0.9rem;
}

.user-edit-input {
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #eee;
    font-size: 1rem;
    transition: all 0.2s ease;
}

.user-edit-input:focus {
    outline: none;
    border-color: #1a237e;
    box-shadow: 0 0 0 3px rgba(26, 35, 126, 0.1);
}

.user-edit-actions {
    grid-column: span 2;
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    margin-top: 24px;
}

@media (max-width: 768px) {
    .user-detail-profile {
        flex-direction: column;
        text-align: center;
    }

    .user-detail-stats {
        justify-content: center;
    }

    .user-edit-grid {
        grid-template-columns: 1fr;
    }

    .user-edit-actions {
        grid-column: span 1;
    }
}
