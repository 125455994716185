.bulk-message-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
}

.message-section, .history-section {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

h2, h3 {
  margin-bottom: 1.5rem;
  color: #333;
  text-align: center;
}

.message-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: bold;
  color: #555;
}

.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  resize: vertical;
  transition: all 0.2s;
}

.form-group textarea:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.form-group textarea:focus {
  outline: none;
  border-color: #25D366;
  box-shadow: 0 0 0 2px rgba(37, 211, 102, 0.2);
}

.help-text {
  color: #666;
  font-size: 0.9rem;
  margin-top: 0.25rem;
}

/* File Input Styling */
.file-input {
  padding: 0.75rem;
  border: 2px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.file-input:hover {
  border-color: #25D366;
  background-color: rgba(37, 211, 102, 0.05);
}

.file-input:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Image Preview */
.image-preview {
  position: relative;
  margin-top: 1rem;
  border-radius: 4px;
  overflow: hidden;
  max-width: 300px;
}

.image-preview img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 4px;
}

.remove-image {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8rem;
  color: #d32f2f;
  transition: all 0.2s;
}

.remove-image:hover:not(:disabled) {
  background-color: #fff;
  color: #b71c1c;
}

.remove-image:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.send-button {
  background-color: #25D366;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.send-button:hover:not(:disabled) {
  background-color: #128C7E;
}

.send-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.7;
}

.status-message {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 4px;
  text-align: center;
}

.status-message.success {
  background-color: #e8f5e9;
  color: #2e7d32;
  border: 1px solid #c8e6c9;
}

.status-message.error {
  background-color: #fdecea;
  color: #d32f2f;
  border: 1px solid #ffcdd2;
}

/* Message History Styles */
.message-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 600px;
  overflow-y: auto;
}

.message-item {
  background-color: #f8f9fa;
  border-radius: 4px;
  padding: 1rem;
  border-left: 4px solid #25D366;
  position: relative;
  transition: all 0.2s;
}

.message-item:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.message-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.phone-number {
  font-weight: bold;
  color: #128C7E;
}

.timestamp {
  color: #666;
}

/* Message Image in History */
.message-image {
  margin: 0.5rem 0;
  border-radius: 4px;
  overflow: hidden;
  max-width: 200px;
}

.message-image img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 4px;
}

.message-content {
  color: #333;
  margin: 0.5rem 0;
  word-break: break-word;
  background-color: #fff;
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid #eee;
}

.message-status {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.message-status.sent {
  color: #25D366;
}

.message-status.failed {
  color: #d32f2f;
}

.error-details {
  text-transform: none;
  font-weight: normal;
}

.resend-button {
  background-color: #128C7E;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s;
  opacity: 0.9;
}

.resend-button:hover:not(:disabled) {
  background-color: #075E54;
  opacity: 1;
}

.resend-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.7;
}

.no-messages {
  text-align: center;
  color: #666;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 4px;
}

/* Loading States */
.loading {
  opacity: 0.7;
  pointer-events: none;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .bulk-message-container {
    grid-template-columns: 1fr;
    max-width: 800px;
  }
  
  .message-section, .history-section {
    padding: 1.5rem;
  }

  .image-preview {
    max-width: 100%;
  }

  .message-image {
    max-width: 100%;
  }
}
