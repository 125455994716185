.image-counter {
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: 500;
}

.image-counter-small {
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 15px;
    font-size: 0.8rem;
    font-weight: 500;
}

/* Delete Modal Styles */
.delete-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1060;
    animation: fadeIn 0.3s ease-out;
}

.delete-modal {
    background: white;
    border-radius: 1rem;
    width: 90%;
    max-width: 500px;
    padding: 2rem;
    position: relative;
    animation: slideIn 0.3s ease-out;
}

.delete-modal-content {
    text-align: center;
}

.delete-modal-icon {
    font-size: 3rem;
    color: #dc3545;
    margin-bottom: 1rem;
    animation: bounceIn 0.5s ease-out;
}

.delete-modal-title {
    color: #2d3748;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.delete-modal-message {
    color: #4a5568;
    margin-bottom: 0.5rem;
}

.service-name {
    font-weight: 600;
    color: #2d3748;
}

.delete-modal-warning {
    color: #dc3545;
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
}

.delete-modal-actions {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
}

.delete-modal-actions button {
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    font-weight: 500;
    transition: all 0.2s;
}

.delete-modal-actions .btn-cancel {
    background-color: #e2e8f0;
    color: #4a5568;
    border: none;
}

.delete-modal-actions .btn-cancel:hover {
    background-color: #cbd5e0;
}

.delete-modal-actions .btn-delete {
    background-color: #dc3545;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.delete-modal-actions .btn-delete:hover {
    background-color: #c82333;
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes bounceIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/* RTL Support */
[dir="rtl"] .delete-modal-actions {
    flex-direction: row-reverse;
}

/* Responsive Design */
@media (max-width: 576px) {
    .delete-modal {
        width: 95%;
        padding: 1.5rem;
    }

    .delete-modal-actions {
        flex-direction: column-reverse;
    }

    .delete-modal-actions button {
        width: 100%;
    }
}
