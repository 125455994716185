.pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin: 2rem 0;
}

.pagination-button {
    min-width: 2.5rem;
    height: 2.5rem;
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    background-color: white;
    color: #4a5568;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
}

.pagination-button:hover:not(:disabled) {
    background-color: #f7fafc;
    border-color: #cbd5e0;
}

.pagination-button.active {
    background-color: #3182ce;
    border-color: #3182ce;
    color: white;
}

.pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination-button.dots {
    border: none;
    background: none;
    cursor: default;
}

.pagination-button.dots:hover {
    background: none;
}
