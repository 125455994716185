@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600;700&display=swap');

.notifications-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Cairo', sans-serif;
}

.notifications-card {
    background: white;
    border-radius: 16px;
    padding: 32px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.page-title {
    color: #2c3e50;
    font-size: 28px;
    margin-bottom: 32px;
    text-align: right;
    font-weight: 600;
}

.form-group {
    margin-bottom: 24px;
}

.form-group label {
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
    color: #34495e;
    font-size: 16px;
}

.form-input, .form-textarea {
    width: 100%;
    padding: 12px 16px;
    border: 2px solid #e9ecef;
    border-radius: 8px;
    font-size: 16px;
    transition: all 0.3s ease;
    font-family: 'Cairo', sans-serif;
}

.form-input:focus, .form-textarea:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.1);
}

.form-textarea {
    resize: vertical;
    min-height: 120px;
}

.select-multiple {
    width: 100%;
    padding: 12px;
    border: 2px solid #e9ecef;
    border-radius: 8px;
    font-size: 16px;
    min-height: 150px;
    font-family: 'Cairo', sans-serif;
}

.select-multiple:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.1);
}

.select-multiple option {
    padding: 8px 12px;
}

.select-help {
    font-size: 14px;
    color: #7f8c8d;
    margin-top: 8px;
}

.checkbox-label {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    user-select: none;
    padding: 8px 0;
}

.checkbox-input {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    cursor: pointer;
}

.send-button {
    width: 100%;
    padding: 14px;
    background: linear-gradient(135deg, #3498db, #2980b9);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: 'Cairo', sans-serif;
}

.send-button:hover:not(:disabled) {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(52, 152, 219, 0.2);
}

.send-button:active:not(:disabled) {
    transform: translateY(0);
}

.send-button:disabled {
    background: #e9ecef;
    border-color: #e9ecef;
    color: #95a5a6;
    cursor: not-allowed;
}

/* Placeholder styling */
::placeholder {
    color: #bdc3c7;
}

/* Custom scrollbar */
.select-multiple::-webkit-scrollbar {
    width: 8px;
}

.select-multiple::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.select-multiple::-webkit-scrollbar-thumb {
    background: #bdc3c7;
    border-radius: 4px;
}

.select-multiple::-webkit-scrollbar-thumb:hover {
    background: #95a5a6;
}

.select-container {
    border: 2px solid #e9ecef;
    border-radius: 8px;
    overflow: hidden;
}

.select-search {
    padding: 12px;
    border-bottom: 2px solid #e9ecef;
}

.search-input {
    width: 100%;
    padding: 10px 16px;
    border: 1px solid #e9ecef;
    border-radius: 6px;
    font-size: 14px;
    transition: all 0.3s ease;
    font-family: 'Cairo', sans-serif;
}

.search-input:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.1);
}

.users-list {
    max-height: 300px;
    overflow-y: auto;
    padding: 8px;
}

.user-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-bottom: 4px;
}

.user-item:hover {
    background-color: #f8f9fa;
}

.user-item.selected {
    background-color: #e3f2fd;
}

.user-info {
    flex: 1;
}

.user-name {
    font-size: 14px;
    font-weight: 500;
    color: #2c3e50;
    margin-bottom: 4px;
}

.user-phone {
    font-size: 12px;
    color: #7f8c8d;
}

.checkbox-wrapper {
    padding-right: 12px;
}

.user-checkbox {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    cursor: pointer;
}

.dropdown-container {
    position: relative;
    width: 100%;
}

.dropdown-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    background: white;
    border: 2px solid #e9ecef;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.dropdown-header:hover {
    border-color: #3498db;
}

.dropdown-header i {
    transition: transform 0.3s ease;
}

.dropdown-header i.open {
    transform: rotate(180deg);
}

.dropdown-content {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    background: white;
    border: 2px solid #e9ecef;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.dropdown-search {
    padding: 12px;
    border-bottom: 1px solid #e9ecef;
}

.dropdown-list {
    max-height: 250px;
    overflow-y: auto;
    padding: 8px;
}

.dropdown-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.dropdown-item:hover {
    background-color: #f8f9fa;
}

.dropdown-item.selected {
    background-color: #e3f2fd;
}

.dropdown-user-info {
    flex: 1;
}

.dropdown-user-name {
    font-size: 14px;
    font-weight: 500;
    color: #2c3e50;
    margin-bottom: 2px;
}

.dropdown-user-phone {
    font-size: 12px;
    color: #7f8c8d;
}

.dropdown-search-input {
    width: 100%;
    padding: 10px 16px;
    border: 1px solid #e9ecef;
    border-radius: 6px;
    font-size: 14px;
    transition: all 0.3s ease;
    font-family: 'Cairo', sans-serif;
}

.dropdown-search-input:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.1);
}

/* Scrollbar Styling */
.dropdown-list::-webkit-scrollbar {
    width: 8px;
}

.dropdown-list::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.dropdown-list::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
}

.dropdown-list::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}

.users-list::-webkit-scrollbar {
    width: 8px;
}

.users-list::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.users-list::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
}

.users-list::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}

.notification-type-selector {
    display: flex;
    gap: 12px;
    margin-top: 8px;
}

.type-button {
    flex: 1;
    padding: 12px 20px;
    border: 2px solid #e9ecef;
    border-radius: 8px;
    background: white;
    color: #2c3e50;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: 'Cairo', sans-serif;
}

.type-button:hover {
    border-color: #3498db;
    background: #f8f9fa;
}

.type-button.active {
    background: #3498db;
    border-color: #3498db;
    color: white;
}

.topic-select {
    width: 100%;
    padding: 12px 16px;
    border: 2px solid #e9ecef;
    border-radius: 8px;
    font-size: 14px;
    color: #2c3e50;
    transition: all 0.3s ease;
    font-family: 'Cairo', sans-serif;
    appearance: none;
    background: white url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%232c3e50' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") no-repeat right 16px center;
    background-size: 16px;
    cursor: pointer;
}

.topic-select:hover {
    border-color: #3498db;
}

.topic-select:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.1);
}

.topic-select option {
    padding: 8px;
    font-size: 14px;
}

.message {
    padding: 12px 16px;
    border-radius: 8px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    animation: slideIn 0.3s ease;
}

.message.error {
    background-color: #fee2e2;
    border: 1px solid #fecaca;
    color: #dc2626;
}

.message.success {
    background-color: #dcfce7;
    border: 1px solid #bbf7d0;
    color: #16a34a;
}

@keyframes slideIn {
    from {
        transform: translateY(-10px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.user-primary {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
}

.user-name {
    font-size: 14px;
    font-weight: 600;
    color: #2c3e50;
}

.user-status {
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 12px;
    font-weight: 500;
}

.user-status.active {
    background-color: #dcfce7;
    color: #16a34a;
}

.user-details {
    display: flex;
    align-items: center;
    gap: 12px;
}

.user-phone {
    font-size: 12px;
    color: #64748b;
}

.user-location {
    font-size: 12px;
    color: #64748b;
    display: flex;
    align-items: center;
    gap: 4px;
}

.user-location i {
    font-size: 10px;
    color: #94a3b8;
}

.no-results {
    padding: 16px;
    text-align: center;
    color: #64748b;
    font-size: 14px;
}

.dropdown-item {
    padding: 12px 16px;
}

.dropdown-item:hover {
    background-color: #f8fafc;
}

.dropdown-item.selected {
    background-color: #f0f9ff;
}

.quick-select-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.quick-select-btn {
    padding: 8px 12px;
    border: 1px solid #4CAF50;
    background-color: white;
    color: #4CAF50;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease;
}

.quick-select-btn:hover {
    background-color: #4CAF50;
    color: white;
}
